.progress {
  display: flex;
  align-items: center;
  z-index: 3;

  &__bar {
    width: 80%;
    height: 8px;
    border-radius: 4px;
    background: $dark;
  }
  &__value {
    height: 100%;
    border-radius: 4px;
    width: 0;
    background: $primary;
    transition: all .2s ease;
  }
  &__label {
    color: $body-color;
    font-weight: 300;
    font-style: italic;
    margin-left: 8px;
    width: 32px;
    text-align: right;
  }
}
