//colors
$white: #ffffff;
$black_100: rgba(0, 0, 0, 1);
$black_90: rgba(0, 0, 0, 0.9);
$black_25: rgba(0, 0, 0, 0.25);
$black_10: rgba(0, 0, 0, 0.1);
$color_athens_gray_approx: #ebedf3;
$color_dodger_blue_approx: #3699ff;

//@extend-elements
//original selectors
//.offcanvas .offcanvas-header.offcanvas-header-navs .offcanvas-close, .offcanvas-mobile .offcanvas-header.offcanvas-header-navs .offcanvas-close
%extend_1 {
  position: absolute;
  top: 0;
  right: 0;
}
.offcanvas-background{
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  &.offcanvas-on {
    opacity: 1;
    >.offcanvas{
    left: 0;
    }
    >.offcanvas-right {
        right: 0;
        left: auto;
    }
    >.offcanvas-right-large {
        right: 0;
        left: auto;
    }
  }
}

.offcanvas {
  pointer-events: all;
  padding: 1.5rem;
  flex-shrink: 0 !important;
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: left 0.3s ease, right 0.3s ease;
  left: -620px;
  width: 600px;
  background: var(--background) 0 0 no-repeat padding-box;
  backdrop-filter: blur(31px);

  box-shadow: 0 1px 9px -3px $black_25;
  &.offcanvas-on {
    left: 0;
  }
  &.offcanvas-right {
    right: -620px;
    left: auto;
    &.offcanvas-on {
      right: 0;
      left: auto;
    }

  }
  &.offcanvas-right-large {
    width: 1000px;
    max-width: 100%;
    right: -1020px;
    left: auto;
    &.offcanvas-on {
      right: 0;
      left: auto;
    }

  }
  .offcanvas-header.offcanvas-header-navs {
    position: relative;
    .offcanvas-close {
      @extend %extend_1;
    }
  }
  .offcanvas-content .offcanvas-demo {
    border: 5px solid $color_athens_gray_approx;
    &.offcanvas-demo-active {
      border: 5px solid $color_dodger_blue_approx;
    }
  }
  .offcanvas-content{
    max-height: calc(100vh - 7rem);
    overflow-y: auto;
  }
}

.offcanvas-mobile {
  flex-shrink: 0 !important;
  .offcanvas-header.offcanvas-header-navs {
    position: relative;
    .offcanvas-close {
      @extend %extend_1;
    }
  }
  .offcanvas-content .offcanvas-demo {
    border: 5px solid $color_athens_gray_approx;
    &.offcanvas-demo-active {
      border: 5px solid $color_dodger_blue_approx;
    }
  }
}

@media screen and(-ms-high-contrast: active),(-ms-high-contrast: none) {
  .offcanvas {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: none !important;
  }
}

@-webkit-keyframes animation-offcanvas-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes animation-offcanvas-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media(max-width: 600px) {
  .offcanvas {
    width: 100% !important;
    padding: 0.5rem;
  }
  .offcanvas-mobile {
    width: 100% !important;
  }
}
@media screen and(max-width: 991.98px) and(-ms-high-contrast: active),(max-width: 991.98px) and(-ms-high-contrast: none) {
  .offcanvas-mobile {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: none !important;
  }
}
