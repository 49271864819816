.background-image.gradient-background::after {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(rgba(var(--primary-numbers), 0.38)), to(#000000FC)) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(
                  180deg, rgba(var(--primary-numbers), 0.38) 0%, #000000FC 100%) 0% 0% no-repeat padding-box;
}

.btn {
  &.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--secondary);
    box-shadow: 1px 1px 3px #000000d1;

    &:hover {
      color: var(--secondary-darker);
      background-color: var(--primary-darker);
      border-color: var(--primary-darker);
      box-shadow: 0 0 10px 0px var(--primary-darker)!important;
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
      background-color: var(--primary-lighter);
      border-color: var(--primary-lighter)
    }
  }

  &.btn-outline-primary {
    box-shadow: 1px 1px 3px #000000d1;
    &:hover {
      color: var(--secondary);
      background-color: var(--primary);
      border-color: var(--primary);
    }

    &:active {
      background-color: var(--primary);
      border-color: var(--primary);
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
      background-color: var(--primary-darker);
      border-color: var(--primary-darker)
    }
  }
}

.arc {
  border: 3px solid var(--primary);
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.full-arc {
  border: 3px solid var(--primary);
}

.progress__value {
  background: var(--primary);
}

.cover-fit.border-pad {
  border-color: var(--primary);
  &.thin {
    border-width: 1px ;
  }
}
.card.card-scenario::after {
  background: var(--primary);
  left: -110px;
}

.text-primary{
  color: var(--primary) !important;
}

.back-button {
  box-shadow: 1px 1px 3px #000000d1;
  &.bg-primary {
    background-color: var(--primary) !important;
  }
  &:hover {
    background-color: var(--primary-darker)!important;
    border-color: var(--primary-darker)!important;
    box-shadow: 0 0 10px 0px var(--primary-darker)!important;
  }
  &:focus {
    background-color: var(--primary-darker)!important;
    border-color: var(--primary-darker)!important;
    box-shadow: 0 0 10px 0px var(--primary-darker)!important;
  }
}

.avatar-placeholder {
  background: var(--primary) !important;
}

a.bg-primary{
  &:hover {
    background-color: var(--primary-darker)!important;
    border-color: var(--primary-darker)!important;
    box-shadow: 0 0 10px 0px var(--primary-darker)!important;
  }
  &:focus {
    background-color: var(--primary-darker)!important;
    border-color: var(--primary-darker)!important;
    box-shadow: 0 0 10px 0px var(--primary-darker)!important;
  }
}
