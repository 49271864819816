// Responsive images (ensure images don't scale beyond their parents)
//
// This is purposefully opt-in via an explicit class rather than being the default for all `<img>`s.
// We previously tried the "images are responsive by default" approach in Bootstrap v2,
// and abandoned it in Bootstrap v3 because it breaks lots of third-party widgets (including Google Maps)
// which weren't expecting the images within themselves to be involuntarily resized.
// See also https://github.com/twbs/bootstrap/issues/18178
.img-fluid {
  @include img-fluid();
}


// Image thumbnails
.img-thumbnail {
  padding: $thumbnail-padding;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  @include border-radius($thumbnail-border-radius);
  @include box-shadow($thumbnail-box-shadow);

  // Keep them at most 100% wide
  @include img-fluid();
}

//
// Figures
//

.figure {
  // Ensures the caption's text aligns with the image.
  display: inline-block;
}

.figure-img {
  margin-bottom: $spacer / 2;
  line-height: 1;
}

.figure-caption {
  @include font-size($figure-caption-font-size);
  color: $figure-caption-color;
}

.cover-fit {
  object-fit: cover;

  &.border-pad {
    padding: 2px;
    border: 3px solid #00B3A4;
    border-radius: 100%;
  }
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  z-index: -10;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 30vh 35vh rgba(0, 0, 0, 0.85);
  -webkit-box-shadow: inset 0 0 30vh 35vh rgba(0, 0, 0, 0.85);

  &.accent {
    box-shadow: inset 0 0 60vh 35vh rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: inset 0 0 60vh 35vh rgba(0, 0, 0, 0.75);
  }

  &.clear {
    box-shadow: inset 0 0 30vh 35vh rgba(255, 255, 255, 0.85);
    -webkit-box-shadow: inset 0 0 30vh 35vh rgba(255, 255, 255, 0.85);
    &.accent {
      box-shadow: inset 0 0 60vh 35vh rgba(255, 255, 255, 0.75);
      -webkit-box-shadow: inset 0 0 60vh 35vh rgba(255, 255, 255, 0.75);
    }
  }

  &.no-gradient{
    box-shadow: unset !important;
    -webkit-box-shadow: unset !important;
  }
}
.background-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 0;


  &::after {
    background: transparent linear-gradient(180deg, rgba(27, 52, 64, 0.5) 0%,rgba(27, 52, 64, 0.3) 80%, rgba(0, 0, 0, 0.0) 100%) 0% 0% no-repeat padding-box;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.arc {
  border: 3px solid $primary;
  border-radius: 50%;
  height: 125px;
  width: 125px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 51%;
  left: 51%;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-50%, -50%);
}

.full-arc {
  border: 3px solid $primary;
  border-radius: 50%;
  position: absolute;
  top: 51%;
  left: 51%;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-50%, -50%);
}
