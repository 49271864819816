/*
 * Globals
 */
@supports(padding:max(0px)) {
  body, header, footer {
    padding-left: unquote('min(0vmin, env(safe-area-inset-left))');
    padding-right: unquote('min(0vmin, env(safe-area-inset-right))');
    padding-bottom: unquote('min(0vmin, env(safe-area-inset-bottom))');
    padding-top: unquote('min(0vmin, env(safe-area-inset-top))');
  }

}

@import "learning_path";
@import "stat_panel";

@media only screen and (orientation:landscape) and (hover: none) and (pointer: coarse){
  .notch{
    padding-left: 2rem;
  }
  .notch-centered{
    display: block;
    min-width: 3rem !important;
    max-width: 3rem !important;
    height: 100%;
    background: black;
  }
  .course-view{
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
/* Links */
a,
a:focus,
a:hover {
  color: var(--secondary-darken);
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: .05rem solid #fff;
}

/*
 * Base structure
 */

html {
  font-size: 12px;
  background: var(--background) !important;
}

html,
body {
  background-color: var(--background);
  height: 100vh;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 992px) {
      height: 100%;
      margin-left: 15px;
      margin-right: 15px;
    }

  }

}

p {
  color: var(--secondary);
}

iframe {
  border: 0px;
  background: transparent;

  &.scorm {
    background: white;
  }
}

body {
  display: -ms-flexbox;
  display: flex;
  background: var(--background);
  font-weight: 300;
}

/*
 * Header
 */

.masthead-brand {
  margin-bottom: 0;
}

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}

/*
 * Footer
 */
.mastfoot {
  color: rgba(255, 255, 255, .5);
}


.btn-half-up {
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  @media screen and (min-width: 768px) {
    padding: 0 2rem;
    font-size: 1.2rem;
  }
}

.btn-half-down {
  border-bottom-left-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  @media screen and (min-width: 768px) {
    padding: 0 2rem;
    font-size: 1.2rem;
  }
}

.sessions-list {
  width: 100vw !important;
  max-width: 100vw !important;
}
@media (max-width: 1200px) {
  .activity-player {
    position: fixed;
    background: black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .change-scenario, .activity-title {
    display: none;
  }

  .player-container {
    padding: 0 !important;
  }

  .player-wrapper {
    position: relative;

    .back-button-container {
      position: absolute;
      top: 10%;
      left: 0;
    }
  }
}

@media (min-width: 1201px) {
  .back-list-button {
    display: none !important;
  }
}

.card-status {
  margin-right: 0.5rem;
  img {
    width: 2rem;
    height: 2rem;
  }
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 5em;
  height: 34px;
  margin-left: 0px;
  box-shadow: 1px 1px 3px #000000d1;

  svg {
    transform: rotate(90deg);
    width: 25px;
  }
}

.search-form {
  position: relative;

  .search-icon {
    position: absolute;
    top: 4px;
    left: 15px;
    width: 35px;
    opacity: 0.75;
  }
}

header {
  position: relative;
  z-index: 2;
}

.card-locked {
  .card-body {
    opacity: 0.5;
  }

  .card-image {
    .rounded-circle {
      & > .arc, & > img {
        opacity: 0.5;
      }
    }
  }
}

// View page

.view-page {

  &.wrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed !important;
    z-index: 100;
    margin: 0;
  }


  @media screen and (max-width: 992px) {

    .card-last-session {
      margin-top: 7rem;
      margin-bottom: 5rem;

      .card-body {
        padding: 0;
      }

      h1 {
        font-size: 1.5rem;
      }
    }
    @media screen and (max-height: 500px) {

      .card-last-session {
        margin-top: 1rem;
      }
    }
  }
}

.sessions-page {
  .main {
    margin-top: unset !important;
    margin-bottom: unset !important;
  }
}

.cursor-hand {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.brand {
  img {
    height: 4.5rem;
  }
}

.avatar-placeholder {
  min-height: 0 !important;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00B3A4;
  color: var(--secondary);
  border-radius: 50%;
  font-weight: 700;
  font-size: 20px;

  &.big {
    font-size: 40px;
    width: 160px;
    height: 160px;
  }
}

.xp-border {
  border-right: 1px solid #ccc;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 7px;
}

.xp-borders {
  min-height: 115px;
  height: 100%;
  position: absolute;
}
.sessions {
  position: absolute;
  height: 100%;
  padding-bottom: 3rem;
  padding-top: 10rem;
  transition: top 300ms ease;
  display: flex;
  &.mt-auto{
    top: 60vh;
    padding-top: 0;
    padding-bottom: 0;
    display: none !important;
  }
  @media (min-width: 1000px) and (min-height: 800px) {
    &.mt-auto{
      display: flex !important;
    }
  }
}
.row.sessions {
  max-height: unset;

  @media screen and (min-width: 970px) {
    max-height: 62vh;
  }

  @media screen and (min-width: 1400px) {
    max-height: 70vh;
  }
}

.modal-content {
  background: #000000e0 0 0 no-repeat padding-box;
}

.modal-header {
  border-bottom: none !important;
}

.badge-title {
  font-size: medium;
}

.badge-disabled {
  opacity: .2;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.course-shadow {
  position: absolute;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  align-self: center;
  box-shadow: inset 0px -13px 12px 3px rgba(0, 0, 0, .6);
  -moz-box-shadow: inset 0px -13px 12px 3px rgba(0, 0, 0, .6);
  -webkit-box-shadow: inset 0px -13px 12px 3px rgba(0, 0, 0, .6);
}

.no-session_text {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  padding: 4rem;
  background: var(--background-transparent) 0 0 no-repeat padding-box;
  box-shadow: 0 1px 9px 3px #00000091;
  border: .4rem solid var(--primary);
  -webkit-backdrop-filter: blur(0.3rem) !important;
  backdrop-filter: blur(0.3rem) !important;
  border-radius: 1rem;
  max-width: 700px;
  max-height: 80vh;
  overflow-y: auto;
  transform-style: preserve-3d;
  transform-origin: center;
  transition: transform 500ms cubic-bezier(.63,-0.01,.39,1);
  text-align: center;
}

.avatar {
  z-index: 1;

  @media screen and (min-width: 768px) {
    max-height: 80vh;
  }

  @media screen and (min-width: 970px) {
    max-height: 90vh;
  }

}

.animate__backInDown {
  animation: backInDown 1s;
  -webkit-animation: backInDown 1s;
  animation-fill-mode: forwards;
}

@keyframes backInDown {
  0% {
    margin-top: auto;
    margin-bottom: auto;
    opacity: 1;
  }
  100% {
    transform: translateY(620px);
    opacity: 0;
  }
}

.dg-btn--cancel {
  color: white;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid white;
  padding: 1rem 2rem;
  font-size: 1.15rem;
  line-height: 1.5;
  border-radius: 2rem;

  &:hover {
    background-color: var(--primary-darker);
    border-color: var(--primary-darker);
  }
}

.dg-btn--ok {
  color: white;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid white;
  padding: 1rem 2rem;
  font-size: 1.15rem;
  line-height: 1.5;
  border-radius: 2rem;

  &:hover {
    background-color: var(--primary-darker);
    border-color: var(--primary-darker);
  }
}

.dg-main-content {
  width: 98%;
  width: calc(98% - 30px);
  max-width: 400px;
  padding: 15px;
  border-radius: 5px;
  margin: 25px auto;
  background-color: #00000078;
  border: .2rem solid var(--primary);
  color: #ffffff;
}

.resource {
  display: inline-table;
}

.progress {
  width: 50%;
  max-width: 260px;
  background: transparent;
  border-radius: 3px;
  overflow: hidden;
  margin: auto;

}

.fade-in {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fadeOut 2s;
  -webkit-animation: fadeOut 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.progress-bar {
  display: block;
  height: 100%;
  background: #2279b3;
  background-size: 300% 100%;
  width: 0;
  animation: progress-animation 4s;
  -webkit-animation: progress-animation 4s;
  animation-delay: 2s;
  -webkit-animation-delay: 2s;

}

@keyframes progress-animation {
  0% {
    width: 0;
  }
  25% {
    width: 25%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%
  }
}

.percentage {
  &:before {
    -webkit-animation: percentage-fast 4s forwards;
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
    content: "0%";
    letter-spacing: 1pt;
    display: flex;
    font-size: 25px;
    justify-content: center;

    @keyframes percentage-fast {
      @for $i from 0 through 100 {
        #{$i * 1%} {
          content: $i + '%';
        }
      }
    }
  }
}

@keyframes ToTopRight {
  0% {
    margin-top: 20%;
    margin-right: 50%;
    transform: scale(1);
  }
  100% {
    margin-top: 0;
    margin-right: 0;
    transform: scale(0.4);
  }
}

.custom-select option {
  background: #000;
  color: #fff;
}

.btn.disabled {
  opacity: 0.45;
  color: #fff;
}

.rotate-left-arrow {
  transform: rotate(0.5turn);

  path {
    fill: #fff;
  }
}

.current-page {
  background: var(--primary);
  border-radius: 50%;
}

.toasted .primary.info, .toasted.toasted-primary.info {
  background: var(--primary);
  top: 35px;
}


.btn-all-sessions-row {
  @media screen and (max-width: 970px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.opacity-0 {
  opacity: 0 !important;
}
.dg-content-footer{
  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
  }
}
.dg-btn{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.dg-content-body{
  border-bottom: 0;
}
.dg-content{
  text-align: center;
}
.sessions-main{
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  flex: 0 1 auto;
}
.sessions-row{
  overflow-y: scroll;
}

.activity-alert {
  width: 98%;
  width: calc(98% - 30px);
  text-align: center;
  max-width: 600px;
  padding: 15px;
  border-radius: 5px;
  margin: 25px auto;
  background-color: #00000078;
  border: .2rem solid var(--primary);

  &.clear {
    background-color: #ffffff78;
  }
}


.forum-separator {
  border-bottom: 1px solid var(--secondary);
  width: 50%;
  margin: auto;
  &.end {
    border-bottom: 3px solid var(--primary) !important;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}
.forum-message {
  blockquote {
    font-style: italic;
    margin: 1rem;
    padding: 2rem;
    border: 1px solid var(--primary) !important;
    border-radius: 0.75rem;
  }
}
.forum-notification{
  display: flex;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  box-shadow: 1px 1px 3px #000000d1;

  &.active{
    background-color: var(--primary);
    border: solid 1px white;
  }
}
.toggle__button {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.toggle__button input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.toggle__button .toggle__switch {
  display:inline-block;
  height:0.75rem;
  border-radius:6px;
  width:2.5rem;
  background: var(--secondary);
  border: solid 1px var(--secondary);
  position:relative;
  margin-right: 1rem;
  transition: all .25s;
}
.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
  content: "";
  position: absolute;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  left: -0.33rem;
  top: -0.5rem;
  transform: translateX(0);
  transition: all .25s cubic-bezier(.5, -.6, .5, 1.6);
}
.toggle__button .toggle__switch::after {
  background: #4D4D4D;
  border: 1px solid var(--secondary);
  box-shadow: 0 0 1px #666;
}
.toggle__button .toggle__switch::before {
  background: #4D4D4D;
  box-shadow: 0 0 0 3px rgba(0,0,0,0.1);
  opacity:0;
}
.active .toggle__switch::after {
  left: 1rem;
  background: var(--primary);
  box-shadow: 0 0 1px var(--primary);
}
.rotable{
  transition: rotate 100ms linear;
}
