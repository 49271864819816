.donut {
  width: 150px;
  height: 150px;
  transform: rotateZ(-90deg) rotateX(180deg);

  &__course {
    background: transparent!important;
  }

  &__progress{
    width: 55px;
    height: 55px;
  }

  &__progress_course{
    width: 105px;
    height: 105px;
  }

  &__strokebackground,
  &__value {
    fill: transparent;
    stroke-width: 2px;
    transition: all .2s ease;
    &__course{
      stroke-width: 1px;
    }
  }
  &__background {
    fill: var(--primary);
  }

  &__strokebackground {
    stroke: var(--secondary);
  }
  &__strokebackground_course {
    stroke: var(--secondary);
    fill: transparent!important;
  }
  &__value {
    stroke: var(--primary);
    stroke-linecap: round;
    stroke-dasharray: 90px;
  }
}

.progress_text {
  bottom: -5px;
  position: absolute;
  left: 50%;
  font-size: 14px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 4px 6px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.progress-enter-active {
  animation: progress-change 0.5s;
}
@keyframes progress-change {
  0% {
    stroke: var(--primary);
    stroke-width: 2px;
  }
  50% {
    stroke: var(--secondary);
    stroke-width: 3px;
  }
  100% {
    stroke: var(--primary);
    stroke-width: 2px;
  }
}
