.learning_path{
  height: 100%;
  max-width: calc(100% - 3rem);
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 500px;
  opacity: 100;
  transition: width 0.1s,opacity 0.5s;
  background: var(--background-transparent);
  padding-top: 2rem;

  .path_header{
    margin-bottom: 1rem;
    align-items: center;
    margin-left: 2rem;
    display: flex;
  }

  &.fold{
    width: 0px;
    opacity: 0;

    .texts{
      display: none !important;

    }
  }

  .activity {
    margin-left: -7px;
    position: relative;
    word-wrap: break-word;

    &:hover {
      &::after {
        opacity: 1;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: -65px;
        background: transparent linear-gradient(90deg,var(--secondary) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
        opacity: 0.15;
      }
    }

    &.current {
      &::after {
        opacity: 1;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: -65px;
        background: transparent linear-gradient(90deg,var(--secondary) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
        opacity: 0.35;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: -108px;
      top: 3px;
      width: 90px;
      height: 90px;
      background: var(--primary);
      transform: matrix(0.97, 0.26, -0.26, 0.97, 0, 0);
      border-radius: 19px;
      opacity: 0;
    }

    .texts{
      display: block;
      transition: display 0.5s;
      margin-right: 0.5rem !important;
      width: 20rem;
    }
  }
  .courses {
    //border-left: 1px solid white;
    max-height: unset;
    padding-top: 5px;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar{
      display: none;
    }

    .course {
      h4 {
        margin: 2rem 0 0.5rem -5px;
      }
      h6{
        margin: 0 0 0.5rem -5px;
        font-style: italic;
        font-size: 10px;
      }

      &:first-of-type {
        h4 {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
